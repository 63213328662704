import { useEffect, useState } from 'react'
import { useRcTranslation } from './useRcTranslation'
import { useFeatures } from '@/components/providers/site/FeatureProvider'
import { TranslationNamespace } from '@/core/translation'

/**
 * Updates locale definitions with organization provided overrides.
 */
export const useOrganizationLocale = () => {
  const [initialized, setInitialized] = useState(false)
  const { attributes } = useFeatures()
  const { i18n } = useRcTranslation()

  useEffect(() => {
    const locales =
      attributes.filter(it => it.path?.startsWith('locale_v2_')) || []

    for (const loc of locales) {
      const lang = loc.path?.replace('locale_v2_', '').replace('_', '-')
      i18n.addResourceBundle(
        lang!,
        TranslationNamespace.Override,
        loc.value,
        true,
        true
      )
      i18n.changeLanguage()
    }
    setInitialized(true)
  }, [attributes, i18n])

  return initialized
}
