import Typography from '@mui/material/Typography'
import React, { useCallback, useEffect, useState } from 'react'
import { RcTrans } from '../../components/atoms/RcTrans'
import { useOrganization } from '../../entity/organization/useOrganization'
import { useUserAccount } from '../../entity/user/useUserAccount'
import { useFirebase } from '@/components/providers/site/FirebaseProvider'
import { usePage } from '@/components/providers/site/PageProvider'

const code = [38, 38, 40, 40, 37, 39, 37, 39, 66, 65]

export const useKonami = () => {
  const [input, setInput] = useState<number[]>([])
  const { user, logEvent } = useFirebase()
  const { account } = useUserAccount()
  const { isOrgSuperAdmin } = useOrganization()
  const { open } = usePage()
  const handleCode = useCallback(async () => {
    if (isOrgSuperAdmin) {
      const token = await user?.getIdToken()
      open(
        undefined,
        <Typography
          sx={{ wordBreak: 'break-all' }}
        >{`${account?.id} - ${user?.uid!} - Bearer ${token}`}</Typography>
      )
    } else {
      logEvent('konami_mode_enabled')
      open(undefined, <RcTrans i18nKey='site.hidden' />)
    }
  }, [isOrgSuperAdmin, open, user, logEvent, account?.id])

  const onKeyUp = useCallback(
    (e: KeyboardEvent) => {
      const newInput = input
      newInput.push(e.keyCode)
      newInput.splice(-code.length - 1, input.length - code.length)

      setInput(newInput)

      if (newInput.join('').includes(code.join(''))) {
        handleCode()
      }
    },
    [input, setInput, handleCode]
  )

  useEffect(() => {
    document.addEventListener('keyup', onKeyUp)
    return () => {
      document.removeEventListener('keyup', onKeyUp)
    }
  }, [onKeyUp])
}
