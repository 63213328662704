'use client'

import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import { RcIconButton } from '@/components/molecules/interactive/RcIconButton'
import { useLocalStorage } from '@/core/hooks/useLocalStorage'

export const TogglePinnedButton = () => {
  const [pinned, setPinned] = useLocalStorage({
    key: 'pin-t1-nav',
    defaultValue: true
  })

  return (
    <Box>
      <Tooltip title={pinned ? 'Collapse' : 'Lock'}>
        <RcIconButton
          onClick={() => setPinned(p => !p)}
          aria-label={pinned ? 'unpin navigation' : 'pin navigation'}
          icon={
            pinned
              ? ['fal', 'chevron-double-left']
              : ['fal', 'chevron-double-right']
          }
        />
      </Tooltip>
    </Box>
  )
}
