import { TooltipProps } from '@mui/material/Tooltip'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import moment from 'moment-timezone'
import { useCallback, useEffect, useState } from 'react'
import { ChatListTabs } from './ChatListTabs'
import { RcSuspense } from '@/components/atoms/RcSuspense'
import { RcTrans } from '@/components/atoms/RcTrans'
import {
  RcIconButton,
  RcIconButtonProps
} from '@/components/molecules/interactive/RcIconButton'
import { RcTooltipButton } from '@/components/molecules/interactive/RcTooltipButton'
import { useNotificationStyles } from '@/components/organisms/site/NotificationIcon'
import { useChat } from '@/components/providers/site/ChatProvider'
import { useIsTablet } from '@/core/hooks/useMediaQueries'
import { useSocialUserContext } from '@/entity/chat/useSocialUserContext'
import { shiver } from '@/core/animations'

export const ChatMobile = ({
  expanded,
  button,
  ...props
}: Partial<RcIconButtonProps> & { expanded?: boolean; button?: boolean }) => {
  const isMobile = useIsTablet()
  const { activity, handleShowChat } = useChat()
  const socialUserContext = useSocialUserContext({ shouldSubscribe: true })

  const hasNewMessages = socialUserContext.activeChats?.some(it => {
    const activityItem = activity?.find(a => a.id === it)
    if (!activityItem) return false
    return (
      !activityItem.lastActive ||
      (activityItem.lastMessage &&
        moment(activityItem.lastActive).isBefore(activityItem.lastMessage))
    )
  })

  const [open, setOpen] = useState(false)
  const style = useNotificationStyles()

  useEffect(() => {
    setOpen(false)
  }, [expanded])

  const handleClick = async () => {
    if (open) {
      setOpen(false)
    } else {
      setOpen(true)
    }
  }

  const handleClose = useCallback(async () => {
    setOpen(false)
  }, [])

  const tooltipProps: Omit<TooltipProps, 'children'> = {
    open: open,
    placement: isMobile ? 'bottom' : 'right-start',
    disableInteractive: false,
    componentsProps: {
      tooltip: {
        sx: style
      }
    },
    PopperProps: { keepMounted: true },
    title: (
      <RcSuspense>
        <ChatListTabs
          handleShowChat={handleShowChat}
          handleClose={handleClose}
        />
      </RcSuspense>
    )
  }

  return (
    <ClickAwayListener touchEvent={false} onClickAway={handleClose}>
      {button ? (
        <RcTooltipButton
          onClick={() => {
            handleClick()
          }}
          size='large'
          icon={['fal', 'messages']}
          badge={hasNewMessages}
          TooltipProps={tooltipProps}
          disableTouchRipple
          variant='text'
          className={`rc-navigation-button ${open ? 'active' : ''}`}
          sx={shiver}
        >
          {expanded ? <RcTrans i18nKey='navigation.messages' /> : null}
        </RcTooltipButton>
      ) : (
        <RcIconButton
          onClick={() => {
            handleClick()
          }}
          size='small'
          IconProps={{ size: 'sm' }}
          icon={['fal', 'messages']}
          badge={hasNewMessages}
          TooltipProps={tooltipProps}
          disableTouchRipple
          sx={shiver}
          {...props}
        />
      )}
    </ClickAwayListener>
  )
}
