import ListItemButton from '@mui/material/ListItemButton'
import Stack from '@mui/material/Stack'
import { take } from 'lodash-es'
import { PartyAvatar } from '../avatar/PartyAvatar'
import { useMyActivities } from '@/entity/party-finder/useMyActivities'

export const UserParties = ({}) => {
  const { activities } = useMyActivities()

  return (
    <Stack direction='column' spacing={1}>
      {take(activities, 3).map(it => (
        <PartyAvatar key={it.id} activity={it} Component={ListItemButton} />
      ))}
    </Stack>
  )
}
