import { NetworkKind } from '@rallycry/api-suite-typescript/dist/models/NetworkKind'
import { RcIconButton } from '../interactive/RcIconButton'
import { extractIconStrings } from '../input/ManageUrls'
import { useNetworkKinds } from '@/core/hooks/i18n/useNetworkKind'

export interface SocialGridItemProps {
  network: NetworkKind
  url?: string
  size?: 'sm' | 'lg'
  tooltip?: string | React.ReactNode
  description?: string
}

export const SocialGridItem: React.FC<SocialGridItemProps> = ({
  url,
  network,
  tooltip,
  description
}) => {
  const { getNetworkIcon } = useNetworkKinds()
  const icon = description
    ? extractIconStrings(description)
    : getNetworkIcon(network)

  return url ? (
    <RcIconButton
      onClick={() => window.open(url)}
      icon={icon}
      TooltipProps={tooltip ? { title: tooltip } : undefined}
    />
  ) : null
}
