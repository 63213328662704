'use client'

import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import React from 'react'
import Box from '@mui/material/Box'
import Fab from '@mui/material/Fab'
import { ChatDesktop } from '../pages/Chat/_ChatDesktop'
import { FeatureSearch } from '../pages/Administration/FeatureSearch'
import { ModalTrigger } from '../organisms/modal/ModalTrigger'
import { NavigationLayout } from '@/components/organisms/navigation/NavigationLayout'
import { NavigationMobileV4 } from '@/components/organisms/navigation/NavigationMobileV4'
import { useFeatures } from '@/components/providers/site/FeatureProvider'
import { useIsMdUp } from '@/core/hooks/useMediaQueries'
import { useOrganization } from '@/entity/organization/useOrganization'
import { ContactFlowLink } from '@/flows/Site/ContactFlow/ContactFlowLink'
import { ContactFlow } from '@/flows/Site/ContactFlow/_ContactFlow'

export const MainLayoutV4 = ({ children }: { children: React.ReactNode }) => {
  const { isOrgSuperAdmin } = useOrganization()
  const { featChat, featChatSupport } = useFeatures()
  const isMdUp = useIsMdUp()
  const showChats = isMdUp && (featChat || featChatSupport)

  return (
    <>
      <Stack direction='row' justifyContent='flexStart' alignItems='stretch'>
        {/* Navigation */}
        {isMdUp ? <NavigationLayout /> : <NavigationMobileV4 />}

        {/* Main Content */}
        <Container
          maxWidth='lg'
          disableGutters
          sx={theme => ({
            minHeight: '100dvh',
            minWidth: isMdUp ? theme.breakpoints.values.sm : undefined,
            pl: { xs: 0, md: 8 },
            pr: { xs: 0, md: 10 },
            py: { xs: 12, md: 7 },
            pb: { xs: 0 }
          })}
        >
          {children}
        </Container>
      </Stack>

      {/* <HelpFab /> */}
      {showChats ? <ChatDesktop /> : null}
      {isOrgSuperAdmin ? <FeatureSearch /> : null}
    </>
  )
}

export const HelpFab = () => {
  return (
    <Box sx={{ position: 'fixed', bottom: 15, right: 15 }}>
      <ModalTrigger>
        {({ handleClose }) => <ContactFlow onComplete={handleClose} />}
      </ModalTrigger>
    </Box>
  )
}
