'use client'

import CssBaseline from '@mui/material/CssBaseline'
import {
  ThemeProvider as MaterialThemeProvider,
  ThemeOptions,
  createTheme
} from '@mui/material/styles'
import React, { useMemo } from 'react'
import Color from 'color'
import { useFeatures } from './FeatureProvider'
import { defaultThemeV4 } from '@/style/themeV4'

/**
 * Provides theme for the site with organization theme overrides
 */
export const ThemeProvider = ({ children }: { children?: React.ReactNode }) => {
  const { themeOverride, featStylePrimaryButton, featStyleSecondaryButton } =
    useFeatures()

  const override = themeOverride as any

  const primary = override?.palette?.primary?.main
    ? override.palette.primary
    : { main: '#FFF' }
  const secondary = override?.palette?.secondary?.main
    ? override.palette.secondary
    : { main: Color(primary.main).darken(0.5).hex() }
  const background = {
    paper: override?.palette?.background?.paper || primary.main
  }

  const theme = createTheme(
    defaultThemeV4(
      {
        ...override,
        palette: {
          // TODO: Re-Enable user preference
          mode: 'dark',
          ...override?.palette,
          primary,
          secondary,
          background

          // mode: userDarkMode ? 'dark' : 'light',
        }
      },
      {
        stylePrimaryBtn: !!featStylePrimaryButton,
        styleSecondaryBtn: !!featStyleSecondaryButton
      }
    )
  )

  return (
    <MaterialThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MaterialThemeProvider>
  )
}
