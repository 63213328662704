'use client'

import { useEffect, useRef, useState } from 'react'
import { HotkeyItem, useHotkeys } from '@mantine/hooks'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import TextField from '@mui/material/TextField'
import { isEmpty } from 'lodash-es'
import { RenderFeatureInput } from './components/OrganizationFeatures'
import {
  DEFAULT_ORG_FEATURES,
  useFeatures
} from '@/components/providers/site/FeatureProvider'
import { NoResults } from '@/components/molecules/text/NoResults'

export const FeatureSearch = () => {
  const scrollRef = useRef<HTMLDivElement>()
  const resultRef = useRef<HTMLDivElement>()
  const features = useFeatures()
  const [search, setSearch] = useState('')
  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    if (modalOpen) {
      scrollRef.current?.scrollIntoView({ behavior: 'instant' })
    }
  }, [search, modalOpen])

  const handleArrow = (direction: 'up' | 'down') => {
    const inputs = resultRef.current?.querySelectorAll('input')
    if (!inputs || !modalOpen) return
    const current = document.activeElement
    const currentIndex = Array.from(inputs).indexOf((current as any) || 0)
    const nextIndex = direction === 'down' ? currentIndex + 1 : currentIndex - 1
    const next = inputs[nextIndex]
    if (next) {
      next.focus()
    }
  }

  const modalHotkey: HotkeyItem = [
    'mod + p',
    () => setModalOpen(true),
    { preventDefault: true }
  ]
  const hotkeys: HotkeyItem[] = modalOpen
    ? [
        modalHotkey,
        ['ArrowDown', () => handleArrow('down')],
        ['ArrowUp', () => handleArrow('up')]
      ]
    : [modalHotkey]

  useHotkeys(hotkeys, [], true) // true = allow on inputs

  const options = Object.keys(DEFAULT_ORG_FEATURES)
    .filter(
      it =>
        it.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
        (DEFAULT_ORG_FEATURES as any)[it]?.description
          .toLocaleLowerCase()
          .includes(search.toLocaleLowerCase()) ||
        (DEFAULT_ORG_FEATURES as any)[it]?.category
          .toLocaleLowerCase()
          .includes(search.toLocaleLowerCase())
    )
    .sort()
    .map(it => {
      const template =
        DEFAULT_ORG_FEATURES[it as keyof typeof DEFAULT_ORG_FEATURES]
      const featureKey = it as keyof typeof features
      const current = features[featureKey]

      return {
        label: featureKey + template.description,
        id: featureKey,
        template,
        current
      }
    })

  return (
    <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
      <Box
        sx={{
          position: 'absolute',
          top: { xs: '5%', md: '20%' },
          left: { xs: '2%', md: '25%' },
          width: { xs: '96%', md: '50%' }
        }}
      >
        <TextField
          value={search}
          onChange={ev => setSearch(ev.target.value)}
          placeholder='Search for a feature'
          sx={theme => ({
            '& .MuiInputBase-root': {
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0
            }
          })}
          fullWidth
          autoFocus
        />
        <Box ref={resultRef}>
          <Box
            sx={theme => ({
              display: 'flex',
              flexDirection: 'column',
              gap: 3,
              padding: 3,
              border: `1px solid ${theme.palette.divider}`,
              borderTop: 0,
              borderBottomLeftRadius: theme.shape.borderRadius,
              borderBottomRightRadius: theme.shape.borderRadius,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              backdropFilter: 'blur(10px)',
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              overflowY: 'auto',
              maxHeight: { xs: '85dvh', md: '55dvh' }
            })}
          >
            <Box ref={scrollRef} />
            {options.map(it => (
              <Box key={it.id} style={{ width: '100%' }}>
                <RenderFeatureInput feat={it.id} />
              </Box>
            ))}
            {isEmpty(options) ? <NoResults /> : null}
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}
