import { getAnalytics, isSupported, logEvent } from 'firebase/analytics'
import { useEffect } from 'react'
import { usePathname } from 'next/navigation'
import { useFirebase } from '@/components/providers/site/FirebaseProvider'

export const useGAHistory = () => {
  const location = usePathname()
  const { firebase } = useFirebase()

  useEffect(() => {
    const analytics = getAnalytics(firebase)
    isSupported().then(isSupported => {
      if (isSupported)
        logEvent(analytics, 'page_view', {
          page_title: document.title,
          page_path: location || '/',
          page_location: window.location.href
        })
    })
  }, [location, firebase])
}
