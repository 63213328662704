'use client'

import { PropsWithChildren } from 'react'
import { SWRConfig, unstable_serialize } from 'swr'
import { unstable_serialize as unstable_serialize_infinite } from 'swr/infinite'
import { getCacheKey } from '@/entity/getCacheKey'

export const SWRProvider = ({
  fallback,
  children
}: PropsWithChildren<{
  fallback?: {
    key: ReturnType<typeof getCacheKey>
    value: any
    paginated?: boolean
  }[]
}>) => {
  // map fallback to new object using unstable_serialize for keys
  const mapped = fallback?.reduce(
    (acc, value) => {
      const serialized = value.paginated
        ? unstable_serialize_infinite(() => value.key)
        : unstable_serialize(value.key)
      return { ...acc, [serialized]: value.value }
    },
    {} as typeof fallback
  )

  return (
    <SWRConfig
      value={{
        fallback: mapped,
        suspense: true,
        revalidateOnMount: true,
        revalidateIfStale: true,
        shouldRetryOnError: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: true
      }}
    >
      {children}
    </SWRConfig>
  )
}
