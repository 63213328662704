import ListItemButton from '@mui/material/ListItemButton'
import Stack from '@mui/material/Stack'
import { some } from 'lodash-es'

import { CompetitionAvatar } from '../avatar/CompetitionAvatar'
import { useCompetitionUserView } from '@/entity/competition/useCompetitionUserView'

export const UserCompetitions = ({}) => {
  const { userView } = useCompetitionUserView()

  return (
    <Stack direction='column' spacing={0}>
      {some(userView?.ongoing)
        ? userView?.ongoing?.map(it => (
            <CompetitionAvatar
              isLive
              key={it.id!}
              competition={it}
              Component={ListItemButton}
              size='xs'
            />
          ))
        : null}
      {some(userView?.upcoming)
        ? userView?.upcoming?.map(it => (
            <CompetitionAvatar
              key={it.id!}
              competition={it}
              Component={ListItemButton}
              size='xs'
            />
          ))
        : null}
    </Stack>
  )
}
