import { RcTrans } from '@/components/atoms/RcTrans'

export const Copyright = () => {
  return (
    <RcTrans
      i18nKey='site.copyright'
      tOptions={{ year: new Date().getFullYear() }}
    />
  )
}
