import { NetworkKind } from '@rallycry/api-suite-typescript/dist/models/NetworkKind'
import React, { PropsWithChildren, useState } from 'react'
import { NavigationLink as Link } from '@/components/organisms/navigation/NavigationLink'
import { ConfirmationDialog } from '@/components/molecules/interactive/ConfirmationDialog'
import { RcTrans } from '@/components/atoms/RcTrans'
import { useOrganization } from '@/entity/organization/useOrganization'
import { useConfig } from '@/entity/site/useConfig'
import { useUserAccount } from '@/entity/user/useUserAccount'
import { useMyContactAccounts } from '@/entity/user/useMyContactAccounts'
import { useTimeoutWindow } from '@/core/hooks/useTimeoutWindow'

export const DiscordPrompt: React.FC<
  PropsWithChildren<Record<string, any>>
> = ({ children }: { children?: React.ReactNode }) => {
  const [open, setOpen] = useState(false)
  const config = useConfig()
  const { organization, profile } = useOrganization()
  const { account } = useUserAccount()
  const {
    handles,
    query: { mutate }
  } = useMyContactAccounts()

  const discord = handles.find(it => it.network === NetworkKind.DISCORD)

  const showLink = useTimeoutWindow(
    config.api + `/oauth2/authorization/${NetworkKind.DISCORD.toLowerCase()}`,
    async () => {
      await mutate()
      window.open(profile?.primaryDiscordInviteUrl)
    }
  )

  const handleOk = () => {
    setOpen(false)
    showLink()
  }

  const handleCancel = () => {
    setOpen(false)
    window.open(profile?.primaryDiscordInviteUrl)
  }

  // skip showing if org does not use discord
  if (!profile?.primaryDiscordInviteUrl) return null

  return discord || !account ? (
    <Link
      href={profile?.primaryDiscordInviteUrl}
      target='_blank'
      rel='noopener'
    >
      {children}
    </Link>
  ) : (
    <>
      <Link onClick={() => setOpen(true)}>{children}</Link>
      <ConfirmationDialog
        open={open}
        message={
          <RcTrans
            i18nKey='site.discord-prompt'
            tOptions={{ organization: organization?.name }}
          />
        }
        cancel={<RcTrans i18nKey='site.goto-discord' />}
        ok={<RcTrans i18nKey='site.link-accounts' />}
        onOk={handleOk}
        onCancel={handleCancel}
      />
    </>
  )
}
