import { Comparison } from '@rallycry/api-suite-typescript/dist/models/Comparison'
import { EntityType } from '@rallycry/api-suite-typescript/dist/models/EntityType'
import { SearchPartyFinderRequest } from '@rallycry/api-suite-typescript/dist/apis/PartyFinderDocumentControllerApi'
import { useUserAccount } from '../user/useUserAccount'
import { EntityOptions } from '../useEntity'
import { usePartyFinderDocument } from './usePartyFinderDocument'

export const useMyActivities = (
  options?: EntityOptions<SearchPartyFinderRequest>
) => {
  const { account } = useUserAccount()
  return usePartyFinderDocument({
    ...options,
    paused: !account,
    request: {
      userId: [account?.id!],
      userIdComparison: Comparison.EQ,
      referenceType: EntityType.ACTIVITY,
      ...options?.request
    }
  })
}
