import Box from '@mui/material/Box'
import { BoxProps } from '@mui/material/Box'

export const LeftScrollBox = ({ children, sx, ...rest }: BoxProps) => {
  return (
    <Box
      sx={{
        direction: 'rtl',
        overflowY: 'auto',
        scrollbarGutter: 'stable',
        ...sx
      }}
      {...rest}
    >
      <Box sx={{ direction: 'ltr', position: 'relative' }}>{children}</Box>
    </Box>
  )
}
