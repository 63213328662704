import { ChatControllerApi } from '@rallycry/social-api-typescript'
import { ChatDocument } from '@rallycry/social-api-typescript/dist/models'
import { doc, getDoc, onSnapshot } from 'firebase/firestore'
import { values } from 'lodash-es'
import { useCallback, useEffect, useMemo } from 'react'
import { EntityType } from '@rallycry/api-suite-typescript/dist/models/EntityType'
import { EntityOptions, useReadEntity } from '../useEntity'
import { useFirebase } from '@/components/providers/site/FirebaseProvider'
import { useSocialController } from '@/core/hooks/useSWRApi'

export const useChatRoomDocument = (
  options: EntityOptions & { shouldSubscribe?: boolean }
) => {
  const { firestore } = useFirebase()
  const { ctrl } = useSocialController(ChatControllerApi)

  const docKey = `Social_Chat/${options.idOrKey}`
  const db = firestore

  const {
    read: { data: schema }
  } = useReadEntity({
    key: 'useSocialChatSchema',
    persist: true,
    ...options,
    idOrKey: 1,
    read: async () => {
      try {
        return await ctrl().getChatSchema()
      } catch (error) {
        console.log(error)
        return { schema: -1 }
      }
    }
  })

  const data = useReadEntity({
    key: 'useChatRoomDocument' + schema?.schema,
    ...options,
    read: async () => {
      try {
        const ref = doc(db, docKey)
        const res = await getDoc(ref)
        if (res.data()?._schema !== schema?.schema) {
          return await ctrl().readChat({ id: options.idOrKey?.toString()! })
        } else {
          return (await res.data()) as ChatDocument
        }
      } catch (error) {
        console.log(error)
        return null
      }
    }
  })

  const leaveChat = useCallback(async () => {
    return ctrl().leaveChat({ id: options.idOrKey?.toString()! })
  }, [ctrl, options.idOrKey])

  const hasData = !!data.read.data
  useEffect(() => {
    if (!options.shouldSubscribe || !hasData) return
    const ref = doc(db, docKey)
    const unsubscribe = onSnapshot(ref, doc => {
      data.read.mutate(doc.data() as ChatDocument)
    })
    return () => unsubscribe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [db, docKey, options.shouldSubscribe, hasData])

  const members = useMemo(() => {
    return values(data?.read?.data?.members || {})
  }, [data?.read?.data?.members])

  const room = data.read.data
  const entityType = (room?.attributes?.['entityType'] ||
    ('' as unknown)) as EntityType
  const entityId = Number(room?.attributes?.['entityId'] || ('' as unknown))
  const childType = (room?.attributes?.['childType'] ||
    ('' as unknown)) as EntityType
  const childId = Number(room?.attributes?.['childId'] || ('' as unknown))

  return {
    ...data,
    room: data.read.data,
    members,
    leaveChat,
    attributes: { entityType, entityId, childType, childId }
  }
}
