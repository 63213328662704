import Typography from '@mui/material/Typography'
import {
  ActionMenu,
  ActionMenuOption
} from '@/components/molecules/interactive/ActionMenu'
import { AvatarText } from '@/components/molecules/text/AvatarText'
import { RootRoute } from '@/core/route-keys'
import { getUserDisplayName } from '@/core/utils'
import { DependentAccounts } from '@/components/pages/Settings/components/DependentAccounts'
import { RcTrans } from '@/components/atoms/RcTrans'
import { useUserAccount } from '@/entity/user/useUserAccount'
import { useGateway } from '@/entity/site/useGateway'
import { useOrganization } from '@/entity/organization/useOrganization'
import { useImpersonation } from '@/components/providers/site/ImpersonationProvider'
import { useFirebase } from '@/components/providers/site/FirebaseProvider'
import { useFeatures } from '@/components/providers/site/FeatureProvider'
import { useNavigation } from '@/core/hooks/useNavigation'

export const NavigationAvatar = ({ avatarOnly }: { avatarOnly?: boolean }) => {
  const { navTo, signOut } = useNavigation()
  const { isOrgModerator } = useOrganization()
  const { account } = useUserAccount()
  const { isComplete } = useGateway()
  const { degraded } = useFirebase()
  const { featChat, featDependentAccounts } = useFeatures()
  const {
    isProfile,
    inheritPermissions,
    impersonation,
    stopImpersonation,
    toggleInheritPermissions
  } = useImpersonation()

  const options: ActionMenuOption[] = []

  isOrgModerator &&
    options.push({
      key: 'administration',
      display: <RcTrans i18nKey='shared.admin-settings' />,
      action: async () => navTo({ root: RootRoute.Administration })
    })

  isOrgModerator &&
    options.push({
      key: 'find-user',
      display: <RcTrans i18nKey='shared.admin-find-user' />,
      action: async () => navTo({ root: RootRoute.User })
    })

  isComplete &&
    options.push({
      key: 'settings',
      display: <RcTrans i18nKey='shared.settings' />,
      action: async () => navTo({ root: RootRoute.Settings })
    })

  featChat &&
    options.push({
      key: 'chat',
      display: <RcTrans i18nKey='shared.chat' />,
      action: async () =>
        navTo({ root: RootRoute.Chat, query: 'scrollTo=true' }, true)
    })

  isComplete &&
    featDependentAccounts &&
    options.push({
      key: 'switch',
      display: <RcTrans i18nKey='shared.switch-profile' />,
      modal: ({ handleClose }) => <DependentAccounts onSelected={handleClose} />
    })

  if (impersonation && !isProfile) {
    options.push({
      key: 'stop',
      display: <RcTrans i18nKey='shared.stop-impersonation' />,
      action: stopImpersonation
    })

    options.push({
      key: 'toggle',
      display: inheritPermissions ? (
        <RcTrans i18nKey='shared.inherit-permissions-enabled' />
      ) : (
        <RcTrans i18nKey='shared.inherit-permissions-disabled' />
      ),
      action: async () => toggleInheritPermissions()
    })
  }

  options.push({
    key: 'logout',
    display: <RcTrans i18nKey='shared.logout' />,
    action: async () => signOut()
  })

  return (
    <ActionMenu
      options={options}
      buttonProps={{
        fullWidth: true,
        sx: {
          display: 'flex',
          justifyContent: 'flex-start',
          px: 3.5,
          py: 7
        }
      }}
    >
      <AvatarText
        src={
          account?.imageOverride || account?._expanded?.user?.[0]?.image || ''
        }
        isOnline
        isDegraded={degraded}
      >
        {avatarOnly ? null : (
          <>
            <Typography textAlign='left' sx={{ userSelect: 'text' }}>
              {getUserDisplayName(account?._expanded?.user?.[0])}
            </Typography>
            <Typography
              variant='subtitle2'
              color='text.secondary'
              textAlign='left'
              sx={{ userSelect: 'text' }}
            >
              {account?.name}
            </Typography>
          </>
        )}
      </AvatarText>
    </ActionMenu>
  )
}
