import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Badge from '@mui/material/Badge'
import Box from '@mui/material/Box'
import Tooltip, { TooltipProps } from '@mui/material/Tooltip'
import React, { forwardRef, useState } from 'react'
import { ButtonProps } from '@mui/material/Button'
import { RcButton } from './RcButton'
import { RcIconProps } from '@/components/atoms/RcIcon'

export interface RcTooltipButtonProps extends ButtonProps {
  icon: IconProp
  badge?: boolean
  IconProps?: Omit<RcIconProps, 'icon'>
  TooltipProps: Omit<TooltipProps, 'children'>
  alignRight?: boolean
}

const defaultTooltipProps = {
  enterDelay: 0,
  placement: 'top'
} as TooltipProps

export const RcTooltipButton = forwardRef(function RcTooltipButton(
  {
    alignRight,
    badge,
    children,
    icon,
    IconProps,
    onClick,
    size = 'small',
    TooltipProps,
    ...rest
  }: RcTooltipButtonProps,
  ref: React.Ref<HTMLButtonElement>
) {
  const [disabled, setDisabled] = useState(false)

  const handleClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    setDisabled(true)
    try {
      await onClick?.(event)
    } finally {
      setDisabled(false)
    }
  }

  return (
    // index.js:1 MUI: You are providing a disabled `button` child to the Tooltip component.
    // A disabled element does not fire events.
    // Tooltip needs to listen to the child element's events to display the title.
    //
    // Add a simple wrapper element, such as a `span`.
    <Tooltip {...defaultTooltipProps} {...TooltipProps}>
      <Box
        justifyContent={alignRight ? 'flex-end' : `inherit`}
        display='inline-block'
        width='100%'
      >
        <RcButton
          size={size}
          ref={ref}
          disabled={rest.disabled || disabled}
          onClick={handleClick}
          {...rest}
        >
          <Badge
            sx={{
              width: 20,
              height: 20,
              alignItems: 'center',
              justifyContent: 'center',
              marginRight: 3
            }}
            invisible={!badge}
            variant='dot'
            color='error'
            overlap='circular'
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
          >
            <FontAwesomeIcon
              icon={icon}
              size={IconProps?.size}
              id={rest.id}
              {...IconProps}
            />
          </Badge>
          {children}
        </RcButton>
      </Box>
    </Tooltip>
  )
})
