import { UserInboxControllerApi } from '@rallycry/api-suite-typescript/dist/apis/UserInboxControllerApi'
import { InboxMarkerCommand } from '@rallycry/api-suite-typescript/dist/models/InboxMarkerCommand'
import { useCallback } from 'react'
import { EntityOptions, useReadEntity } from '../useEntity'
import { useUserAccount } from './useUserAccount'
import { useController } from '@/core/hooks/useSWRApi'

export const useUserInboxMarker = (options?: EntityOptions) => {
  const { account } = useUserAccount()
  const { ctrl } = useController(UserInboxControllerApi)

  const entity = useReadEntity({
    key: 'useUserInboxMarker',
    idOrKey: account?.id,
    ...options,
    read: req =>
      ctrl({
        expectedStatuses: [403],
        fallbackResponse: {}
      }).readUserInboxMarker(req),
    // eslint-disable-next-line  @typescript-eslint/no-unused-vars
    update: (_, cmd: InboxMarkerCommand, expand) =>
      ctrl().saveUserInboxMarker({
        InboxMarkerCommand: cmd,
        expand
      })
  })

  const markAsRead = () =>
    entity.update(account?.id!, {
      markAcknowledged: entity.read.data?.inbox?.sequence,
      markRead: entity.read.data?.inbox?.sequence,
      markImportantAck: entity.read.data?.inbox?.importantSequence,
      markImportantRead: entity.read.data?.inbox?.importantSequence
    })

  const mutate = entity.read.mutate
  const loadMarker = useCallback(() => mutate(), [mutate])

  return {
    ...entity,
    marker: entity.read.data,
    markAsRead,
    loadMarker
  }
}
