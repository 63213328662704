import ClickAwayListener from '@mui/material/ClickAwayListener'
import Popper from '@mui/material/Popper'
import Stack from '@mui/material/Stack'
import { ReactNode, useState } from 'react'

export const usePopperMenu = ({
  transparent
}: { transparent?: boolean } = {}) => {
  const [popperContent, setPopperContent] = useState<ReactNode>(null)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const isOpen = !!anchorEl

  const open = (event: React.MouseEvent<HTMLElement>, content?: ReactNode) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
    setPopperContent(
      <ClickAwayListener
        onClickAway={ev => {
          // keep selects from clicking away (https://github.com/mui/material-ui/issues/12034)
          if ((ev?.target as any)?.localName === 'body') return
          setAnchorEl(null)
        }}
      >
        <Stack
          direction='column'
          spacing={1}
          sx={theme => ({
            m: 3,
            minWidth: 100,
            borderRadius: 1,
            backgroundColor: transparent ? '' : 'rgba(0, 0, 0, 0.5)',
            backdropFilter: transparent ? '' : 'blur(10px)',
            boxShadow: transparent ? '' : '1px 1px 4px 0px rgba(0, 0, 0, 0.10)',
            '& .MuiFormControlLabel-label': {
              userSelect: 'none'
            }
          })}
        >
          {content}
        </Stack>
      </ClickAwayListener>
    )
  }

  const close = () => setAnchorEl(null)

  const PopperElement = (
    <Popper
      open={isOpen}
      anchorEl={anchorEl}
      sx={theme => ({
        zIndex: theme.zIndex.modal
      })}
    >
      {popperContent}
    </Popper>
  )

  return { PopperElement, isOpen, open, close }
}
