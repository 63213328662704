import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { NavigationLink } from './NavigationLink'
import { useOrganization } from '@/entity/organization/useOrganization'
import { useIsMobile } from '@/core/hooks/useMediaQueries'
import { useNavigation } from '@/core/hooks/useNavigation'
import { RootRoute } from '@/core/route-keys'
import { ImageWithFallback } from '@/components/atoms/ImageWithFallback'

/**
 * Container for Organization image / name.
 */
export const NavigationOrganization = ({
  displayText,
  large,
  transparent
}: {
  displayText?: boolean
  large?: boolean
  transparent?: boolean
}) => {
  const { organization } = useOrganization()
  const isMobile = useIsMobile()
  const { getPath } = useNavigation()

  return (
    <NavigationLink
      to={getPath({ root: RootRoute.Root })}
      underline='none'
      color='inherit'
    >
      <Stack
        direction='row'
        alignItems='center'
        spacing={1}
        sx={theme => ({
          paddingX: { xs: 0, sm: 2 },
          cursor: 'pointer',
          borderRadius: 5,
          backgroundColor: transparent
            ? 'transparent'
            : 'rgba(255, 156, 253, 0.08)'
        })}
      >
        <Box
          sx={theme => ({
            display: 'flex',
            justifyContent: 'center',
            marginRight: displayText ? theme.spacing(2) : 0,
            padding: 1,
            borderRadius: 20
          })}
        >
          {organization?.image ? (
            <ImageWithFallback
              src={organization?.image || ''}
              alt={organization?.name || ''}
              width={large ? 60 : 34}
              height={large ? 60 : 34}
              priority
            />
          ) : null}
        </Box>
        {displayText || !isMobile ? (
          <Box pr={2}>
            <Typography color='text.primary' variant={large ? 'h2' : 'h3'}>
              {organization?.name}
            </Typography>
          </Box>
        ) : null}
      </Stack>
    </NavigationLink>
  )
}
