import Typography from '@mui/material/Typography'
import { CompetitionResource } from '@rallycry/api-suite-typescript/dist/models/CompetitionResource'
import Tooltip from '@mui/material/Tooltip'
import { NavigationLink } from '../navigation/NavigationLink'
import {
  AvatarText,
  AvatarTextProps
} from '@/components/molecules/text/AvatarText'
import { NavigationLink as Link } from '@/components/organisms/navigation/NavigationLink'
import { useNavigation } from '@/core/hooks/useNavigation'
import {
  CompetitionInfoRoute,
  CompetitionRoute,
  RootRoute
} from '@/core/route-keys'
import { ExpansionType, expand } from '@/core/expand'

export interface CompetitionAvatarProps {
  competition: CompetitionResource
  Component?: React.ElementType<any>
  onClick?: () => any
}

/*
 * Avatar with auto navigation to competition resource
 */
export const CompetitionAvatar = ({
  onClick,
  competition,
  Component,
  ...rest
}: CompetitionAvatarProps & AvatarTextProps) => {
  const { getPath } = useNavigation()

  const avatar = (
    <AvatarText src={competition.image} variant='rounded' {...rest}>
      <Typography
        variant='h4'
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: 160
        }}
      >
        {competition.name}
      </Typography>
    </AvatarText>
  )

  return onClick ? (
    <Link underline='none' style={{ color: 'inherit' }} onClick={onClick}>
      {Component ? <Component tabIndex={-1}>{avatar}</Component> : avatar}
    </Link>
  ) : (
    <NavigationLink
      underline='none'
      color='inherit'
      to={getPath({
        root: RootRoute.Competition,
        rootId: competition.id
      })}
    >
      {Component ? <Component tabIndex={-1}>{avatar}</Component> : avatar}
    </NavigationLink>
  )
}
