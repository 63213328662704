import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import { Theme, ThemeProvider } from '@mui/material/styles'
import { deepmerge } from '@mui/utils'
import { ModalCardFooter } from './ModalCardFooter'
import { ModalCardHeader } from './ModalCardHeader'
import { ModalProvider } from './ModalProvider'
import { PoweredByRc } from '@/components/pages/Site/PoweredByRc'
import { RcSuspense } from '@/components/atoms/RcSuspense'

export const RoundedModalCard = ({
  children
}: {
  children?: React.ReactNode
}) => {
  return (
    <ThemeProvider
      theme={theme =>
        deepmerge(theme, {
          components: {
            MuiCard: { styleOverrides: { root: { borderRadius: 25 } } }
          }
        } as Theme)
      }
    >
      <Container maxWidth='xs' sx={{ paddingTop: 25 }}>
        <Card
          sx={theme => ({
            backgroundColor: theme.palette.background.default,
            overflow: 'visible'
          })}
        >
          <CardContent
            sx={{
              padding: 8,
              '&:last-child': {
                paddingBottom: 8
              }
            }}
          >
            <ModalProvider>
              <ModalCardHeader />
              <Box sx={{ padding: 5, paddingBottom: 5 }}>
                <RcSuspense height={'50dvh'}>{children}</RcSuspense>
              </Box>
              <ModalCardFooter />
            </ModalProvider>
            <PoweredByRc />
          </CardContent>
        </Card>
      </Container>
    </ThemeProvider>
  )
}
