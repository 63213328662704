import { NetworkKind } from '@rallycry/api-suite-typescript/dist/models/NetworkKind'
import { OrganizationProfileResource } from '@rallycry/api-suite-typescript/dist/models/OrganizationProfileResource'
import { filter, isEmpty, some } from 'lodash-es'
import React from 'react'
import { TutorialCard } from '../../Competition/CompetitionTutorial'
import { RcTrans } from '@/components/atoms/RcTrans'
import { RcButton } from '@/components/molecules/interactive/RcButton'
import { SectionHeader } from '@/components/molecules/text/SectionHeader'
import { CardsSection } from '@/components/organisms/card/CardsSection'
import { NavigationLink } from '@/components/organisms/navigation/NavigationLink'
import { PageItem } from '@/components/organisms/page/PageItem'
import { PageItemContent } from '@/components/organisms/page/PageItemContent'
import { useFeatures } from '@/components/providers/site/FeatureProvider'
import { expander } from '@/core/expand'
import { useLocalStorage } from '@/core/hooks/useLocalStorage'
import { useNavigation } from '@/core/hooks/useNavigation'
import { DiscoveryRoute, RootRoute, SettingsRoute } from '@/core/route-keys'
import { TutorialType } from '@/entity/competition/useCompetitionTutorial'
import { useOrganization } from '@/entity/organization/useOrganization'
import { useGateway } from '@/entity/site/useGateway'
import { useMyContactAccounts } from '@/entity/user/useMyContactAccounts'
import { useUserSelfOrgCommunities } from '@/entity/user/useUserSelfOrgCommunities'

export interface TutorialTask {
  complete?: boolean
  description?: React.ReactNode
  file?: string
  id: string
  action: React.ReactNode
  header: React.ReactNode
  headerComplete: React.ReactNode
  type: TutorialType
  handle?: () => void
  dismissable?: string
}

export const useOrgTutorialTasks = () => {
  const [dismissed, setDismissed] = useLocalStorage<string[]>({
    key: 'tutorial',
    defaultValue: []
  })
  const [highlightPf, setHighlightPf] = useLocalStorage({
    key: 'highlight-pf',
    defaultValue: true
  })
  const { organization } = useOrganization()
  const { orgCommunities } = useUserSelfOrgCommunities()
  const { handles } = useMyContactAccounts()
  const { getPath } = useNavigation()
  const {
    imgTutorialDiscord,
    imgTutorialGame,
    imgTutorialCommunity,
    featHideCommunity
  } = useFeatures()
  const { res, featPartyFinderActivity } = useGateway()

  const hasJoinedCommunity = some(orgCommunities)
  const hasJoinedCompetition = !res?.cta?.competition?.join
  const hasAddedDiscord = !!handles.find(
    it => it.network === NetworkKind.DISCORD
  )
  const tutorialTasks: TutorialTask[] = []

  const dismiss = (id: string) => {
    setDismissed(s => [...s, id])
  }

  const settings = expander<OrganizationProfileResource>(
    organization,
    'organizationProfile'
  )

  if (!featHideCommunity) {
    tutorialTasks.push({
      id: 'joinCommunity',
      complete: hasJoinedCommunity || dismissed?.includes('joinCommunity'),
      description: <RcTrans i18nKey='home:join.community-description' />,
      file: imgTutorialCommunity as string,
      action: (
        <NavigationLink
          to={getPath({
            root: RootRoute.Discovery,
            subRoute: DiscoveryRoute.Communities
          })}
          underline='none'
        >
          <RcButton variant='contained' size='medium'>
            <RcTrans i18nKey='home:join.community-action' />
          </RcButton>
        </NavigationLink>
      ),
      header: <RcTrans i18nKey='home:join.community-prompt' />,
      headerComplete: <RcTrans i18nKey='home:join.community-done' />,
      type: TutorialType.TASK,
      dismissable: 'joinCommunity'
    })
  }
  if (settings?.primaryDiscordInviteUrl) {
    tutorialTasks.push({
      id: 'joinDiscord',
      complete: hasAddedDiscord || dismissed?.includes('joinDiscord'),
      description: (
        <RcTrans
          i18nKey='home:join.discord-description'
          tOptions={{
            organization: organization?.name
          }}
        />
      ),
      file: imgTutorialDiscord as string,
      action: (
        <NavigationLink
          to={getPath({
            root: RootRoute.Settings,
            subRoute: SettingsRoute.AccountInfo
          })}
          underline='none'
        >
          <RcButton variant='contained' size='medium'>
            <RcTrans i18nKey='home:link-discord-action' />
          </RcButton>
        </NavigationLink>
      ),
      header: <RcTrans i18nKey='home:link-discord-prompt' />,
      headerComplete: <RcTrans i18nKey='home:link-discord-done' />,
      type: TutorialType.TASK,
      dismissable: 'joinDiscord'
    })
  }

  tutorialTasks.push({
    id: 'joinCompetition',
    complete: hasJoinedCompetition || dismissed?.includes('joinCompetition'),
    description: <RcTrans i18nKey='home:join.competition-description' />,
    file: imgTutorialGame as string,
    action: (
      <NavigationLink
        to={getPath({
          root: RootRoute.Discovery,
          subRoute: DiscoveryRoute.Competitions
        })}
        underline='none'
      >
        <RcButton variant='contained' size='medium'>
          <RcTrans i18nKey='home:join.competition-action' />
        </RcButton>
      </NavigationLink>
    ),
    header: <RcTrans i18nKey='home:join.competition-prompt' />,
    headerComplete: <RcTrans i18nKey='home:join.competition-done' />,
    type: TutorialType.TASK,
    dismissable: 'joinCompetition'
  })

  featPartyFinderActivity &&
    tutorialTasks.push({
      id: 'highlightPf',
      description: <RcTrans i18nKey='home:highlight-pf-description' />,
      complete: !highlightPf || dismissed?.includes('highlightPf'),
      action: (
        <NavigationLink
          to={getPath({
            root: RootRoute.PartyFinder
          })}
          underline='none'
        >
          <RcButton variant='contained' size='medium'>
            <RcTrans i18nKey='home:highlight-pf-action' />
          </RcButton>
        </NavigationLink>
      ),
      header: <RcTrans i18nKey='home:highlight-pf-prompt' />,
      headerComplete: <RcTrans i18nKey='home:highlight-pf-done' />,
      handle: () => setHighlightPf(false),
      type: TutorialType.TASK,
      dismissable: 'highlightPf'
    })

  const filtered = filter(
    tutorialTasks,
    it => !it.complete && !dismissed?.includes(it.id)
  )

  return { filtered, dismiss }
}

export const Tutorial = () => {
  const { filtered, dismiss } = useOrgTutorialTasks()

  if (isEmpty(filtered)) return null

  return (
    <PageItem contained={false}>
      <PageItemContent pb={0}>
        <SectionHeader title={<RcTrans i18nKey='home:info.tutorial' />} />
      </PageItemContent>
      <PageItemContent px={{ xs: 0, sm: 4 }}>
        <CardsSection
          align='flex-start'
          cardItems={filtered}
          render={(item, idx) => (
            <TutorialCard item={item} index={idx} handleDismiss={dismiss} />
          )}
        />
      </PageItemContent>
    </PageItem>
  )
}
