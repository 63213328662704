import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { AvatarProps } from '@mui/material/Avatar'
import { PartyFinderDocument } from '@rallycry/api-suite-typescript/dist/models/PartyFinderDocument'
import { first } from 'lodash-es'
import { NavigationLink } from '../navigation/NavigationLink'
import { AvatarText } from '@/components/molecules/text/AvatarText'
import { RootRoute } from '@/core/route-keys'
import { RcTrans } from '@/components/atoms/RcTrans'
import { useNavigation } from '@/core/hooks/useNavigation'

export interface PartyAvatarProps extends AvatarProps {
  activity: PartyFinderDocument | undefined
  Component?: React.ElementType<any>
  onClick?: () => void
}

export const PartyAvatar: React.FC<PartyAvatarProps> = ({
  activity,
  Component,
  ...rest
}) => {
  const { getPath } = useNavigation()
  const games = activity?.games?.map(it => it.name!)

  const avatar = (
    <AvatarText
      isOnline={activity?.user?.activeRecently}
      src={first(activity?.games)?.icon}
      variant='rounded'
      {...rest}
    >
      <Stack direction='row' spacing={3}>
        <Typography variant='body0'>{activity?.name}</Typography>
      </Stack>
      <Typography variant='subtitle2' color='textSecondary'>
        {activity?.membersCount} / {activity?.membersMax || '∞'} -{' '}
        {games?.join(', ') || <RcTrans i18nKey='shared.undecided' />}
      </Typography>
    </AvatarText>
  )

  return (
    <NavigationLink
      underline='none'
      color='inherit'
      to={getPath({
        root: RootRoute.PartyFinder,
        rootId: activity?.reference?.id
      })}
    >
      {Component ? <Component>{avatar}</Component> : avatar}
    </NavigationLink>
  )
}
