import { useLocalStorage } from '@mantine/hooks'
import { useIsLgUp } from './useMediaQueries'

export const useNavPin = () => {
  const isLgUp = useIsLgUp()
  const [pinned] = useLocalStorage({
    key: 'pin-t1-nav',
    defaultValue: true
  })
  return isLgUp && pinned
}
