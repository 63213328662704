'use client'

import Badge from '@mui/material/Badge'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { isEmpty, some } from 'lodash-es'
import moment from 'moment-timezone'
import { useState } from 'react'
import { ChatList } from './ChatList'
import { RcTrans } from '@/components/atoms/RcTrans'
import { useSocialUserContext } from '@/entity/chat/useSocialUserContext'
import { useChat } from '@/components/providers/site/ChatProvider'

export const ChatListTabs = ({
  handleShowChat,
  handleClose
}: {
  handleShowChat: (id?: string | undefined) => void
  handleClose?: () => Promise<void>
}) => {
  const { activity } = useChat()
  const context = useSocialUserContext()
  const [tab, setTab] = useState<'pinned' | 'active'>(
    some(context.pinnedChats) ? 'pinned' : 'active'
  )

  const hasPinnedUnread = context?.pinnedChats?.some(it => {
    const activityItem = activity?.find(a => a.id === it)
    if (!activityItem) return false
    return (
      !activityItem.lastActive ||
      (activityItem.lastMessage &&
        moment(activityItem.lastActive).isBefore(activityItem.lastMessage))
    )
  })
  const hasGeneralUnread = context.activeChats
    ?.filter(it => !context.pinnedChats?.includes(it))
    ?.some(it => {
      const activityItem = activity?.find(a => a.id === it)
      if (!activityItem) return false
      return (
        !activityItem.lastActive ||
        (activityItem.lastMessage &&
          moment(activityItem.lastActive).isBefore(activityItem.lastMessage))
      )
    })

  return (
    <Stack height='100%' direction='column'>
      <Paper sx={{ p: 2, borderRadius: 0 }}>
        {some(context.pinnedChats) ? (
          <Tabs value={tab} variant='fullWidth'>
            <Tab
              value='pinned'
              label={
                <Badge
                  color='error'
                  variant={hasPinnedUnread ? 'dot' : 'standard'}
                  sx={{ px: 1 }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                >
                  <RcTrans i18nKey='shared.chat-focused' />
                </Badge>
              }
              onClick={ev => {
                ev.preventDefault()
                ev.stopPropagation()
                return setTab('pinned')
              }}
            />
            <Tab
              value='active'
              label={
                <Badge
                  color='error'
                  variant={hasGeneralUnread ? 'dot' : 'standard'}
                  sx={{ px: 1 }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                >
                  <RcTrans i18nKey='shared.chat-other' />
                </Badge>
              }
              onClick={ev => {
                ev.preventDefault()
                ev.stopPropagation()
                return setTab('active')
              }}
            />
          </Tabs>
        ) : null}
      </Paper>

      {/* always render both pinned/active so new messages process correctly */}
      <Paper
        sx={{
          overflowY: 'scroll',
          height: '100%',
          borderRadius: 0,
          display:
            some(context.pinnedChats) && tab === 'pinned' ? 'default' : 'none'
        }}
      >
        <ChatList
          filter={'pinned'}
          showChat={id => {
            handleShowChat(id)
            handleClose?.()
          }}
        />
      </Paper>
      <Paper
        sx={{
          overflowY: 'scroll',
          height: '100%',
          borderRadius: 0,
          display:
            isEmpty(context.pinnedChats) || tab === 'active'
              ? 'default'
              : 'none'
        }}
      >
        <ChatList
          filter={'active'}
          showChat={id => {
            handleShowChat(id)
            handleClose?.()
          }}
        />
      </Paper>
    </Stack>
  )
}
