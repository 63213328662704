import { request } from 'graphql-request'
import { isEmpty } from 'lodash-es'
import { EntityOptions, useQueryEntity } from '../useEntity'

export const useTwitchOnline = (
  options: EntityOptions<{ identifiers: string[] }>
) => {
  const entity = useQueryEntity({
    key: 'twitch-online',
    suspense: false,
    paused: isEmpty(options?.request?.identifiers?.filter(it => !!it)),
    ...options,
    read: async req =>
      request(
        'https://gql.twitch.tv/gql',
        getQuery(req?.identifiers || []),
        undefined,
        { 'Client-ID': 'kimne78kx3ncx6brgo4mv6wki5h1ko' }
      ) as any
  })

  return entity.query.data?.[0] as {
    [key: string]: { stream: { id: string } }
  }
}

const getQuery = (identifiers: string[]) => `query {
  ${identifiers
    .map(
      (id, i) => `${id}: user(login: "${id}") {
    stream {
      id
    }
  }`
    )
    .join('\n')}
}`
