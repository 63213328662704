import ListItemButton from '@mui/material/ListItemButton'
import Stack from '@mui/material/Stack'
import { some } from 'lodash-es'

import { CommunityKind } from '@rallycry/api-suite-typescript/dist/models/CommunityKind'
import { CommunityAvatar } from '../avatar/CommunityAvatar'
import { useUserSelfOrgCommunities } from '@/entity/user/useUserSelfOrgCommunities'

export const UserCommunities = ({}) => {
  const { orgCommunities } = useUserSelfOrgCommunities()

  return (
    <Stack direction='column' spacing={0}>
      {some(orgCommunities)
        ? orgCommunities
            .filter(it => it.kind === CommunityKind.BASIC)
            .map(it => (
              <CommunityAvatar
                key={it.id!}
                community={it}
                Component={ListItemButton}
                size='xs'
                skipTags
              />
            ))
        : null}
    </Stack>
  )
}
