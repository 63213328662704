import MenuItem from '@mui/material/MenuItem'
import { useParams, usePathname, useRouter } from 'next/navigation'
import { i18nConfig } from '../../../../../i18n.config.mjs'
import { RcSelect } from '@/components/molecules/input/RcSelect'
import { RcTrans } from '@/components/atoms/RcTrans'

export const SiteLocale = () => {
  const { locale } = useParams()
  const router = useRouter()
  const pathname = usePathname()

  // t('locale.en')
  // t('locale.pt-BR')
  // t('locale.fr-CA')
  // t('locale.de-DE')
  return (
    <RcSelect
      property='locale'
      source={{ locale: locale as string }}
      update={async value => {
        // current locale is "en" then append the new locale to the current path
        // otherwise replace the first segment with new locale
        const newPath =
          locale === i18nConfig.defaultLocale
            ? `/${value.locale}${pathname}`
            : pathname.replace(/^\/[^/]+/, `/${value.locale}`)
        router.push(newPath)
      }}
    >
      {i18nConfig.locales.map(it => (
        <MenuItem key={it} value={it}>
          <RcTrans i18nKey={`locale.${it}`} />
        </MenuItem>
      ))}
    </RcSelect>
  )
}
