import { inspect } from '@xstate/inspect'
import { some } from 'lodash-es'
import { useEffect } from 'react'
import { useNavigation } from './useNavigation'
import { useFeatures } from '@/components/providers/site/FeatureProvider'
import { useFirebase } from '@/components/providers/site/FirebaseProvider'
import { usePage } from '@/components/providers/site/PageProvider'
import { RootRoute } from '@/core/route-keys'
import { useOrganization } from '@/entity/organization/useOrganization'
import { useImpersonation } from '@/components/providers/site/ImpersonationProvider'

const SkipRoutes = [
  RootRoute.Landing,
  RootRoute.Login,
  RootRoute.Registration,
  RootRoute.Register,
  RootRoute.EmailAuthRedirect,
  RootRoute.Oauth2,
  RootRoute.OauthCallback,
  RootRoute.FrostGiantSteam,
  RootRoute.FrostGiantSteamCallback,
  RootRoute.FrostGiantSteamError,
  RootRoute.VerifyDomainCode,
  RootRoute.VerifyEmailCode,
  RootRoute.Contact,
  RootRoute.Settings,
  RootRoute.ResetPassword
]

const isPathInRoutes = (path: string, routes: string[]) =>
  some(routes, it => path.toLowerCase().includes(it.toLowerCase()))

export const useRedirectHistory = () => {
  const { user } = useFirebase()
  const { path } = useNavigation()
  const { setRedirect } = usePage()

  useEffect(() => {
    // NOOP for logged in users
    if (user) return

    // NOOP for certain paths that make no sense to redirect back-to after login
    if (isPathInRoutes(path, SkipRoutes)) return

    try {
      // Keep track of most recent path
      setRedirect(path)
    } catch {
      // NOOP no session storage support
    }
  }, [user, path, setRedirect])
}

export const useOrgRedirect = () => {
  const { orgKey } = useOrganization()
  const { featOrgRedirect } = useFeatures()

  useEffect(() => {
    const { protocol, host, pathname } = window.location
    if (!featOrgRedirect || pathname.includes(RootRoute.Administration)) return
    const replacedPath = pathname.split('/').slice(2).join('/')
    window.location.href = `${protocol}//${host}/${featOrgRedirect}/${replacedPath}`
  }, [orgKey, featOrgRedirect])
}

export const useImpersonationOrg = () => {
  const { organization, isOrgModerator } = useOrganization()
  const { setImpersonation } = useImpersonation()

  useEffect(() => {
    if (!isOrgModerator) return
    setImpersonation({
      organization: organization?.id
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization?.id, isOrgModerator])
}

// activates up xstate inspector for development and debugging
export const useXState = () => {
  const { devXstate } = useFeatures()
  useEffect(() => {
    if (!devXstate) return
    inspect({ iframe: false })
  }, [devXstate])
}
