'use client'

import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import moment from 'moment-timezone'
import { ChatInstance } from './ChatInstance'
import { ModalProvider } from '@/components/organisms/modal/ModalProvider'
import { RcSuspense } from '@/components/atoms/RcSuspense'
import { useChat } from '@/components/providers/site/ChatProvider'

export const ChatDesktop = () => {
  const { activity, handleCloseChat, handleActivate, activeChats } = useChat()

  return (
    <>
      <Box
        sx={theme => ({
          zIndex: theme.zIndex.modal,
          position: 'fixed',
          bottom: 0,
          right: 5
        })}
      >
        <Stack direction='row' spacing={2} alignItems='flex-end'>
          {activeChats?.map(activeChat => {
            const hasUnread = activity?.some(
              it =>
                it.id === activeChat.id &&
                (!it.lastMessage ||
                  moment(it.lastActive).isBefore(it.lastMessage))
            )
            return (
              <RcSuspense key={activeChat.id} skipError height={60}>
                <Paper
                  elevation={4}
                  sx={theme => ({
                    zIndex: theme.zIndex.snackbar,
                    height: activeChat.focused ? 'min(80vh,500px)' : 43,
                    width: activeChat.focused ? 350 : 200,
                    border: `1px solid ${theme.palette.divider}`,
                    borderBottom: 0,
                    borderRadius: 1,
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                    borderColor:
                      hasUnread && !activeChat.focused
                        ? theme.palette.warning.main
                        : theme.palette.divider,
                    transition: 'height .25s ease-in, width .25s ease-in'
                  })}
                >
                  <ModalProvider>
                    <ChatInstance
                      id={activeChat.id}
                      focused={activeChat.focused}
                      onActivate={() => handleActivate(activeChat.id)}
                      onClose={() => handleCloseChat(activeChat.id)}
                      autoFocus
                    />
                  </ModalProvider>
                </Paper>
              </RcSuspense>
            )
          })}
        </Stack>
      </Box>
    </>
  )
}
