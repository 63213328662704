import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useEffect, useState } from 'react'
import moment from 'moment-timezone'
import Grow from '@mui/material/Grow'
import { last } from 'lodash-es'
import { EntrantState } from '@rallycry/social-api-typescript'
import { usePopperMenu } from '@/core/hooks/usePopperMenu'
import { borderAnimation, swirlySx } from '@/core/animations'
import { Countdown } from '@/components/molecules/text/Countdown'
import { RcIconButton } from '@/components/molecules/interactive/RcIconButton'
import { useMatchmEntry } from '@/entity/matchm/useMatchmEntry'
import { useSocialUserContext } from '@/entity/chat/useSocialUserContext'
import { useNavigation } from '@/core/hooks/useNavigation'
import { CompetitionRoute, RootRoute } from '@/core/route-keys'

export const MatchMakingQueue = ({ expanded }: { expanded?: boolean }) => {
  const [actioned, setActioned] = useState(false)
  const [matchup, setMatchup] = useState<Date>()
  const { navTo } = useNavigation()

  const { matchmEntries } = useSocialUserContext()
  const { matchm, leave, viewMatchDetails } = useMatchmEntry({
    idOrKey: last(matchmEntries)
  })
  const menu = usePopperMenu({ transparent: true })

  useEffect(() => {
    setActioned(false)
  }, [matchm?.dateEntered])

  useEffect(() => {
    if (matchm?.state === 'VOTING') setMatchup(moment().add(1, 'm').toDate())
  }, [matchm?.state])

  if (!matchm || matchm?.state === EntrantState.INITIAL) return null

  const handleMatchNav = async () => {
    const detail = await viewMatchDetails(matchm?.matchElected!)
    navTo({
      root: RootRoute.Competition,
      rootId: detail._expanded?.competition?.[0]?.id,
      subRoute: CompetitionRoute.Match,
      subId: detail?.id
    })
  }

  const content = (
    <Box
      sx={theme => ({
        width: '100%',
        minWidth: 250,
        height: 105,
        position: 'relative'
      })}
    >
      <Box
        sx={theme => ({
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          borderRadius: expanded ? 2 : 5,
          ...borderAnimation()
        })}
      />

      <Box
        sx={{
          m: 0.5,
          backgroundColor: 'primary.dark',
          backgroundImage: 'url(/dust.svg)',
          backgroundBlendMode: 'overlay',
          ...swirlySx,
          borderRadius: 2,
          position: 'absolute',
          overflow: 'hidden',
          backdropFilter: 'blur(0px)', // cancel inner backdrop blur
          top: 0,
          left: 0,
          width: 'calc(100% - 4px)',
          height: 'calc(100% - 4px)'
        }}
      >
        <Box p={3} pb={2}>
          <Typography variant='h3' noWrap>
            Searching for an opponent
          </Typography>
        </Box>
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          sx={theme => ({
            p: 3,
            fill: 'linear-gradient(270deg, rgba(56, 136, 255, 0.60) -20.13%, rgba(56, 136, 255, 0.00) 100%)',
            backdropFilter: 'blur(4px)',
            stroke: '#3888FF',
            strokeWidth: 1
          })}
        >
          <Typography variant='subtitle2' color='text.secondary'>
            <Countdown target={new Date(matchm?.dateEntered!)} countUp />
          </Typography>
          {matchm?.state === EntrantState.ENTERED ? (
            <Button
              disabled={actioned}
              size='small'
              onClick={async () => {
                setActioned(true)
                try {
                  await leave(matchm)
                } finally {
                  setActioned(false)
                }
              }}
            >
              Leave Queue
            </Button>
          ) : matchm?.state === EntrantState.MATCHELECTED ? (
            <Button onClick={handleMatchNav} size='small'>
              View Match
            </Button>
          ) : (
            matchm?.state
          )}
        </Stack>
      </Box>
    </Box>
  )

  return (
    <>
      {expanded ? (
        <Grow in appear style={{ transformOrigin: '0 100% 0' }}>
          {content}
        </Grow>
      ) : (
        <Box
          sx={theme => ({
            minWidth: 32,
            height: 32,
            position: 'relative'
          })}
        >
          <Box
            sx={theme => ({
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              borderRadius: 2,
              ...(menu.isOpen ? {} : borderAnimation())
            })}
          />
          <Box
            sx={{
              m: 0.5,
              backgroundColor: expanded ? 'primary.dark' : 'common.black',
              backgroundImage: expanded ? 'url(/dust.svg)' : undefined,
              borderRadius: expanded ? 2 : 5,
              position: 'absolute',
              overflow: 'hidden',
              top: 0,
              left: 0,
              width: 'calc(100% - 4px)',
              height: 'calc(100% - 4px)'
            }}
          ></Box>
          <Box
            sx={{
              position: 'absolute',
              top: 1.5,
              left: 1.5
            }}
          >
            <RcIconButton
              IconProps={{ size: 'sm' }}
              onClick={ev => menu.open(ev, content)}
              icon={['fal', 'swords']}
            />
          </Box>

          {menu.PopperElement}
        </Box>
      )}
    </>
  )
}
