import { ButtonProps } from '@mui/material/Button'
import { RcButton } from '@/components/molecules/interactive/RcButton'
import { DiscoveryRoute, RootRoute } from '@/core/route-keys'
import { RcTrans } from '@/components/atoms/RcTrans'
import { useUserAccount } from '@/entity/user/useUserAccount'
import { useNavigation } from '@/core/hooks/useNavigation'

export const SignUpButton = ({ ...props }: ButtonProps) => {
  const { navTo } = useNavigation()
  const { account } = useUserAccount()

  return account ? (
    <RcButton
      color='primary'
      onClick={() =>
        navTo({
          root: RootRoute.Discovery,
          subRoute: DiscoveryRoute.Competitions
        })
      }
      {...props}
    >
      <RcTrans i18nKey='shared.play-now' />
    </RcButton>
  ) : (
    <RcButton
      color='primary'
      onClick={() => navTo({ root: RootRoute.Register })}
      {...props}
    >
      <RcTrans i18nKey='shared.sign-up' />
    </RcButton>
  )
}
