'use client'

import Box from '@mui/material/Box'
import { NavigationMobileV4 } from '../organisms/navigation/NavigationMobileV4'
import { RoundedModalCard } from '@/components/organisms/modal/RoundedModalCard'

export const BlockedLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <NavigationMobileV4 skipAlerts />
      <Box p={1} py={12}>
        <RoundedModalCard>{children}</RoundedModalCard>
      </Box>
    </>
  )
}
