import moment from 'moment-timezone'
import 'moment/locale/pt-br'
import 'moment/locale/fr-ca'
import { useEffect } from 'react'
import { useParams, usePathname, useRouter } from 'next/navigation'
import { useUserAccount } from '../../entity/user/useUserAccount'
import { useRcTranslation } from './useRcTranslation'
import { useFirebase } from '@/components/providers/site/FirebaseProvider'

/**
 * Updates locale to site setting.
 */
export const useLocale = () => {
  const { locale } = useParams()
  const { i18n } = useRcTranslation()
  const { setLanguage } = useFirebase()

  useEffect(() => {
    i18n.changeLanguage((locale as string) || 'en')
  }, [locale, i18n])

  const i18nLang = i18n.language
  useEffect(() => {
    setLanguage(i18nLang)
  }, [setLanguage, i18nLang])

  useEffect(() => {
    moment.locale(i18nLang)
  }, [i18nLang])
}
