import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { useEffect, useState } from 'react'
import { usePathname } from 'next/navigation'
import { ModalCard } from '../modal/ModalCard'
import { ModalProvider } from '../modal/ModalProvider'
import { Footer } from '../site/Footer'
import { RcButton } from '@/components/molecules/interactive/RcButton'
import { RootRoute } from '@/core/route-keys'
import { RcTrans } from '@/components/atoms/RcTrans'
import { useIsMdUp } from '@/core/hooks/useMediaQueries'
import { useNavigation } from '@/core/hooks/useNavigation'

export const NavigationFooter = ({
  expanded = true
}: {
  expanded?: boolean
}) => {
  const pathname = usePathname()
  const { navTo } = useNavigation()
  const isMdUp = useIsMdUp()

  const [showFooter, setShowFooter] = useState(false)

  useEffect(() => {
    if (pathname) {
      setShowFooter(false)
    }
  }, [pathname])

  return (
    <>
      <Stack
        justifyContent={expanded ? 'space-between' : 'flex-start'}
        direction='row'
      >
        <RcButton
          size='small'
          variant='text'
          onClick={() => setShowFooter(!showFooter)}
        >
          <RcTrans i18nKey='site.footer.more' />
        </RcButton>
        {expanded || !isMdUp ? (
          <>
            <RcButton
              size='small'
              variant='text'
              onClick={() => navTo({ root: RootRoute.Privacy })}
            >
              <RcTrans i18nKey='site.footer.privacy' />
            </RcButton>
            <RcButton
              size='small'
              variant='text'
              onClick={() => navTo({ root: RootRoute.TermsOfUse })}
            >
              <RcTrans i18nKey='site.footer.terms' />
            </RcButton>
          </>
        ) : null}
      </Stack>
      <ModalProvider>
        <ModalCard
          open={showFooter}
          onClose={() => setShowFooter(false)}
          drawer
          noFooter
          noHeader
        >
          <Footer />
        </ModalCard>
      </ModalProvider>
    </>
  )
}
