import {
  PartyFinderDocumentControllerApi,
  SearchPartyFinderRequest
} from '@rallycry/api-suite-typescript/dist/apis/PartyFinderDocumentControllerApi'
import moment from 'moment-timezone'
import { EntityOptions, useQueryEntity } from '../useEntity'
import { useController } from '@/core/hooks/useSWRApi'

const now = moment().startOf('hour').toDate()

export const usePartyFinderDocument = (
  options: EntityOptions<SearchPartyFinderRequest>
) => {
  const { ctrl } = useController(PartyFinderDocumentControllerApi)

  const entity = useQueryEntity({
    key: 'usePartyFinderDocument',
    paused: !options?.request,
    ...options,
    read: req =>
      ctrl({
        expectedStatuses: [401, 403],
        fallbackResponse: { content: [] }
      }).searchPartyFinder({ date: now, ...req })
  })

  return {
    ...entity,
    activities: entity.flat
  }
}
