import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { NavigationLink } from '@/components/organisms/navigation/NavigationLink'
import { RcTrans } from '@/components/atoms/RcTrans'
import { useFeatures } from '@/components/providers/site/FeatureProvider'
import { ImageWithFallback } from '@/components/atoms/ImageWithFallback'

export const PoweredByRc = () => {
  const { featRallyCryFooter } = useFeatures()

  if (!featRallyCryFooter) return null

  return (
    <Stack
      direction='row'
      spacing={1}
      justifyContent='center'
      alignItems='center'
      pt={2}
    >
      <ImageWithFallback
        src='https://cdn.rallycryapp.com/play/key_art/logos/logo192.png'
        alt='Rally Cry Logo'
        width={25}
        height={25}
      />
      <NavigationLink href='https://about.rallycry.gg' underline='none'>
        <Typography
          variant='subtitle1'
          color='text.secondary'
          textAlign='center'
        >
          <RcTrans
            i18nKey='shared.powered-by-rc'
            tOptions={{ year: new Date().getFullYear() }}
          />
        </Typography>
      </NavigationLink>
    </Stack>
  )
}
