import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import ListItemButton from '@mui/material/ListItemButton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { EntityType } from '@rallycry/api-suite-typescript/dist/models/EntityType'
import { isEmpty, last, take } from 'lodash-es'
import moment from 'moment-timezone'
import { useEffect } from 'react'
import { RcIconButton } from '@/components/molecules/interactive/RcIconButton'
import { AvatarText } from '@/components/molecules/text/AvatarText'
import { RcIcon } from '@/components/atoms/RcIcon'
import { RcSuspense } from '@/components/atoms/RcSuspense'
import { RcTrans } from '@/components/atoms/RcTrans'
import { useChatMessages } from '@/entity/chat/useChatMessages'
import { useChatRoomDocument } from '@/entity/chat/useChatRoomDocument'
import { useSocialUserContext } from '@/entity/chat/useSocialUserContext'
import { useChat } from '@/components/providers/site/ChatProvider'
import { useConfig } from '@/entity/site/useConfig'

export const ChatList = ({
  filter,
  showChat
}: {
  filter: 'pinned' | 'active' | 'all'
  showChat: (id: string) => any
}) => {
  const { social } = useConfig()
  const { schema, activeChats, pinnedChats, pinChat, unpinChat } =
    useSocialUserContext()

  const rooms =
    filter === 'all'
      ? activeChats
      : filter === 'pinned'
      ? pinnedChats
      : activeChats?.filter(it => !pinnedChats?.includes(it))

  if (isEmpty(rooms))
    return (
      <Stack
        direction='column'
        mt={10}
        p={5}
        spacing={3}
        justifyContent='center'
        alignItems='center'
      >
        <RcIcon icon={['fad', 'cat-space']} size='5x' />
        <Typography variant='body2' display='inline-block' maxWidth={245}>
          <RcTrans i18nKey='shared.no-active-chats' />
        </Typography>
        <Typography variant='caption' color='text.secondary' align='center'>
          {!schema ? `Unable to contact ${social}` : null}
        </Typography>
      </Stack>
    )

  return (
    <>
      {take(rooms, 10)?.map(room => (
        <RcSuspense key={room} skipError height={64}>
          <Box key={room} px={2}>
            <ChatListRow
              id={room}
              button
              showChat={showChat}
              pinned={!!pinnedChats?.find(a => a === room)}
              togglePin={() => {
                !!pinnedChats?.find(a => a === room)
                  ? unpinChat(room)
                  : pinChat(room)
              }}
            />
          </Box>
        </RcSuspense>
      ))}
    </>
  )
}

export const ChatListRow = ({
  id,
  pinned,
  showChat,
  togglePin,
  button
}: {
  id: string
  pinned: boolean
  showChat: (id: string) => any
  togglePin: () => any
  button?: boolean
}) => {
  const { getChatActivity, updateChatActivity } = useChat()
  const { room, attributes } = useChatRoomDocument({ idOrKey: id })

  const { messages } = useChatMessages({
    idOrKey: id,
    limit: 1,
    shouldSubscribe: true
  })

  const lastMessage = last(messages)

  useEffect(() => {
    updateChatActivity(id, {
      lastMessage: lastMessage?.dateCreated!
    })
  }, [id, updateChatActivity, lastMessage])

  const activity = getChatActivity(id)
  const hasUnread =
    !activity?.lastActive ||
    (activity?.lastMessage &&
      moment(activity.lastActive).isBefore(activity.lastMessage))

  const { entityType, childType } = attributes

  return (
    <Stack
      component={button ? ListItemButton : Stack}
      direction='row'
      alignItems='center'
      width='100%'
      maxWidth='100%'
    >
      <AvatarText
        src={room?.image}
        onClick={() => {
          showChat(id)
        }}
        isDegraded
        variant='rounded'
        badgeIcon={hasUnread ? ['fal', 'message-exclamation'] : undefined}
      >
        <Typography
          fontWeight={hasUnread ? 800 : 400}
          variant='body1'
          overflow='hidden'
          whiteSpace='nowrap'
          textOverflow='ellipsis'
          maxWidth={155}
        >
          {room?.name || id}
        </Typography>
        <Chip
          label={
            childType === EntityType.BRACKETMATCH ? (
              <RcTrans i18nKey='shared.match-entity' />
            ) : entityType === EntityType.COMMUNITY ? (
              <RcTrans i18nKey='shared.community-entity' />
            ) : entityType === EntityType.ACTIVITY ? (
              <RcTrans i18nKey='shared.activity-entity' />
            ) : (
              <RcTrans i18nKey='shared.competition-entity' />
            )
          }
        />
      </AvatarText>
      <RcIconButton
        color={pinned ? 'warning' : 'default'}
        icon={pinned ? ['fal', 'star'] : ['fal', 'star']}
        onClick={async ev => {
          ev.stopPropagation()
          ev.preventDefault()
          await togglePin()
        }}
      />
    </Stack>
  )
}
