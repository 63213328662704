import { UserInboxControllerApi } from '@rallycry/api-suite-typescript/dist/apis/UserInboxControllerApi'
import { EntityOptions, useQueryEntity } from '../useEntity'
import { useGateway } from '../site/useGateway'
import { useController } from '@/core/hooks/useSWRApi'

export const useUserInboxMessages = (options?: EntityOptions) => {
  const { isComplete } = useGateway()
  const { ctrl } = useController(UserInboxControllerApi)

  const query = useQueryEntity({
    key: 'useUserInboxMessages',
    paused: !isComplete,
    ...options,
    request: { ...options?.request },
    read: req => ctrl().readUserInboxMessages(req)
  })

  return { ...query, messages: query.flat }
}
